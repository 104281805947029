<template>
  <div class="dashboard_container">
    <div class="dashboard_head">
       <div class="dashboard_head-title">HOME</div>
    </div>
    <div class="dashboard_content">
      <div class="dashboard_title">Welcome {{logUserData.name}}</div>
      <div class="dashboard_subtitle" v-if="activePhrase">{{activePhrase.title || ''}}</div>
      <div class="dashboard_cards">
        <div>
          <YearCalendar
                  v-model="year"
                  :activeDates.sync ="activeDates"
                  @toggleDate="toggleDate"
                  lang="en"
                  prefixClass="custom_calendar"
                  :showYearSelector="showYearSelector"
          ></YearCalendar>
        </div>
<!--        <div class="flip-card" v-for="card in motivation_cards" :key="card.id">-->
<!--          <div class="flip-card-inner">-->
<!--            <div class="flip-card-front">-->
<!--              <img src="@/assets/images/logo_small.svg" alt="Avatar">-->
<!--            </div>-->
<!--            <div class="flip-card-back">-->
<!--              <div>{{card.message}}</div>-->
<!--              <div class="arrow_down"></div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>
<script>
  import {mapActions, mapGetters} from "vuex";
  import YearCalendar from 'vue-material-year-calendar'

  export default  {
    name: 'Dashboard',
    data() {
      return {
        motivation_cards: [
          {
            id: 1,
            message: 'Motivation message'
          },
          {
            id: 2,
            message: 'Motivation message'
          },
          {
            id: 3,
            message: 'Motivation message'
          },
          {
            id: 4,
            message: 'Motivation message'
          },
          {
            id: 5,
            message: 'Motivation message'
          },
          {
            id: 6,
            message: 'Motivation message'
          },
          {
            id: 7,
            message: 'Motivation message'
          },
          {
            id: 8,
            message: 'Motivation message'
          },
          {
            id: 9,
            message: 'Motivation message'
          },
          {
            id: 10,
            message: 'Motivation message'
          },
        ],
        year: 2023,
        activeDates: [],
        showYearSelector: false,
        activePhrase: null
      }
    },
    components: { YearCalendar },
    created() {
      this.getAllPhrases()

    },
    computed: {
      ...mapGetters(['logUserData'])
    },
    methods: {
      ...mapActions(['getPhrases']),
      toggleDate (dateInfo) {
        this.$router.push({path: '/calendar', query: {dateInfo}})
        // console.log(dateInfo)  // { date: '2010-10-23', selected: true }
      },
      getAllPhrases() {
        this.getPhrases()
        .then((res) => {
          this.activePhrase = res.data[Math.floor(Math.random()*res.data.length)]
        })
      }
    }
  }
</script>
<style scoped lang="scss">
  .dashboard {
    &_container {
      width: 100%;
    }
    &_head {
      width: 100%;
      height: 73px;
      background: linear-gradient(180deg, #003761 0%, #00528C 79.64%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;
      position: sticky;
      top: 0;
      z-index: 3;
      &-title {
        color: #FFFFFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    &_content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 60px 10px;
    }
    &_title {
      color: #11334D;
      font-size: 38px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      @media screen and (max-width: 1680px) {
        font-size: 32px;
      }
    }
    &_subtitle {
      color: #11334D;
      font-size: 38px;
      font-style: italic;
      font-weight: 500;
      line-height: normal;
      @media screen and (max-width: 1680px) {
        font-size: 30px;
      }
    }
    &_cards {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 10px 50px;
      @media screen and (max-width: 1680px) {
        padding: 10px 20px;
      }
      ::v-deep .custom_calendar {
        /*background: grey;*/
        /*&.red {*/
        /*  background-color: red;*/
        /*  color: white;*/
          /*&:after {*/
          /*  background-image: url("~@/assets/icons/deactive.svg") no-repeat center;*/
          /*  background-size: 100% 100%;*/
          /*}*/

        /*}*/
      }
      ::v-deep .container__months {
        background: transparent;
      }
      ::v-deep .vue-calendar__container {
        box-shadow: none;
        background: transparent;
      }
      ::v-deep .container__month {
        &.p-0 {
          display: none;
        }
        .calendar {
          border: 5px solid #00A99D;;
          border-radius: 8px;
          filter: drop-shadow(0px 2px 6px #004475);
          min-height: 270px;
          & .calendar__day {
            height: 26px;
          }
        }
      }
      .flip-card {
        margin: 23px 27px;
        background-color: transparent;
        width: 236px;
        height: 200px;
        perspective: 1000px;
      }

      .flip-card-inner {
        position: relative;
        border-radius: 12px;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.6s;
        transform-style: preserve-3d;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      }

      .flip-card:hover .flip-card-inner {
        transform: rotateX(180deg);
      }

      .flip-card-front, .flip-card-back {
        position: absolute;
        border-radius: 12px;
        filter: drop-shadow(0px 4px 12px #004475);
        border: 5px solid rgba(0, 82, 140, 1);
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
      }

      .flip-card-front {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #00A99D;
      }

      .flip-card-back {
        background-color: #ffffff;
        color: #11334D;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        transform: rotateX(180deg);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 60px 20px 15px;
        & .arrow_down {
          width: 30px;
          height: 15px;
          background: url("~@/assets/icons/arrow_down.svg");
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
  }
  .vue-calendar__container {
    @media screen and (max-width: 1680px) {
      max-width: 1100px;
      width: 100%;
    }
  }
</style>
